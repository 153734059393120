export function Observable(initalValue) {
    let listeners = new Set();
    const self = {
        get value() {
            return initalValue;
        },
        set value(val) {
            initalValue = val;
            listeners.forEach((callback) => callback(self.value));
        },
        subscribe(callback) {
            listeners.add(callback);
            return () => listeners.delete(callback);
        },
        subscribeAndRun(callback) {
            listeners.add(callback);
            callback(self.value);
            return () => listeners.delete(callback);
        },
        unsubscribe(callback) {
            listeners.delete(callback);
        },
        flushListeners() {
            listeners = new Set();
        },
    };
    return self;
}
export function computed(inputs, callback) {
    const output = Observable(callback(...inputs.map((i) => i.value)));
    const listener = () => (output.value = callback(...inputs.map((i) => i.value)));
    inputs.forEach((i) => i.subscribe(listener));
    return output;
}
export function computedAsync(input, callback) {
    const output = Observable({ type: "loading" });
    const listener = async () => {
        output.value = { type: "loading" };
        try {
            output.value = {
                type: "success",
                value: await callback(input.value),
            };
        }
        catch (error) {
            if (error instanceof Error)
                output.value = { type: "error", error };
            else
                output.value = {
                    type: "error",
                    error: new Error(JSON.stringify(error)),
                };
        }
    };
    input.subscribeAndRun(listener);
    return output;
}

import "isomorphic-fetch";
import { QueryStringBuilder } from "../utils/query-string-builder";
import { renderNews } from "./news-card";
class SearchService {
    magnoliaLanguage;
    render;
    workspace;
    displayOption;
    startPath;
    limit = 15;
    offset = 0;
    data = [];
    elementsById = {
        submit: () => document.getElementById("submit"),
        showMore: () => document.getElementById("show-more"),
        datepickerstart: () => document.getElementById("datepickerstart"),
        datepickerend: () => document.getElementById("datepickerend"),
        keywordsearch: () => document.getElementById("keywordsearch"),
        searchInfo: () => document.getElementById("searchInfo"),
        searchResultsContainer: () => document.getElementById("searchResultsContainer"),
        btnShowMoreNews: () => document.getElementById("btnShowMoreNews"),
    };
    constructor(magnoliaLanguage, render, workspace = "idw", displayOption = "upcoming", startPath = "") {
        this.magnoliaLanguage = magnoliaLanguage;
        this.render = render;
        this.workspace = workspace;
        this.displayOption = displayOption;
        this.startPath = startPath;
        this.init();
    }
    init() {
        const initButtons = () => {
            const submit = this.elementsById.submit();
            submit?.addEventListener("click", (e) => {
                this.reset();
                this.submit(e);
            });
            this.elementsById
                .btnShowMoreNews()
                ?.addEventListener("click", (e) => {
                this.submit(e);
            });
            submit?.click();
        };
        document.readyState !== "complete"
            ? (window.onload = () => {
                initButtons();
            })
            : initButtons();
    }
    reset() {
        this.data = [];
        this.offset = 0;
        const container = this.elementsById.searchResultsContainer();
        container?.innerHTML?.trim() && (container.innerHTML = "");
    }
    async search(query) {
        const response = await fetch(`${window.location.origin}/.rest/${this.workspace}/search?${query}`);
        try {
            if (response.status === 200)
                return (await response.json());
            else
                throw new Error(await response.text());
        }
        catch (err) {
            console.error(err);
            return null;
        }
    }
    submit(e) {
        e?.stopPropagation();
        e?.preventDefault();
        this.search(this.getQueryString())
            .then((data) => {
            if (!data)
                return;
            else if (data.total === 0) {
                this.updateSearchInfo(data.total);
                this.updateShowMoreButton(data.total);
                this.reset();
                return;
            }
            else if (data.items.length === 0)
                return;
            this.data.push(...data.items);
            data.items.forEach((item) => this.appendContent(this.render(item)));
            this.offset += this.limit;
            this.updateSearchInfo(data.total);
            this.updateShowMoreButton(data.total);
        })
            .catch(console.error)
            .finally(() => { });
    }
    updateSearchInfo(totalFetchableElements) {
        const info = this.elementsById.searchInfo();
        if (!info)
            return;
        info.innerText = `${totalFetchableElements} ${this.magnoliaLanguage.toLowerCase().includes("de")
            ? "Element wurden gefunden"
            : "elements were found"}`;
    }
    updateShowMoreButton(totalFetchableElements) {
        if (this.offset >= totalFetchableElements) {
            this.elementsById.btnShowMoreNews()?.classList.add("d-none");
        }
        else {
            this.elementsById.btnShowMoreNews()?.classList.remove("d-none");
        }
    }
    appendContent(content) {
        const container = this.elementsById.searchResultsContainer();
        if (!container)
            return;
        return container.appendChild(content);
    }
    getQueryString() {
        const start = this.elementsById.datepickerstart()?.value;
        const end = this.elementsById.datepickerend()?.value;
        const needle = this.elementsById.keywordsearch()?.value;
        return QueryStringBuilder()
            .add("offset", this.offset.toString())
            .add("limit", this.limit.toString())
            .add("to", end ? this.formatDate(end) : "")
            .add("from", start ? this.formatDate(start) : "")
            .add("needle", needle ?? "")
            .add("locale", this.magnoliaLanguage)
            .add("displayOption", this.displayOption)
            .add("startPath", this.startPath)
            .build();
    }
    formatDate(input) {
        return input.split("-").reverse().join(".");
    }
    get language() {
        return window.navigator.language;
    }
}
const service = (magnoliaLanguage, displayOption, startPath) => new SearchService(magnoliaLanguage, renderNews, "idw", displayOption, startPath);
// @ts-ignore
window.newsSearchService = service;

"use strict";
"use-strict";
window.revokeCookie = function revokeCookie() {
    const cookieName = "yt-noCookie";
    if (typeof window === "undefined")
        return;
    window.document.cookie = `${cookieName}=false; expires=${new Date().toUTCString()}; max-age=0; path=/;`;
};
(function initRevokeYtButtons() {
    const buttons = document.querySelectorAll("[data-revoke-yt-cookie]");
    buttons.forEach((button) => {
        button.addEventListener("click", (e) => {
            e.preventDefault();
            window.revokeCookie();
        });
    });
})();

import { Icon } from "../icons/icon";
import { translate } from "../overview/utils";
export const PHD_DETAIL_BASE_URL = "https://api.daad.de/api/ajax/rig/detail/";
export class PhdDetail {
    root;
    constructor(id) {
        this.root = (document.getElementById(id) ?? null);
        if (!this.root) {
            throw new Error(`Element with id ${id} not found`);
        }
    }
    async queryData() {
        try {
            const params = new URLSearchParams(window.location.search);
            const response = await fetch(PHD_DETAIL_BASE_URL +
                document.documentElement.lang +
                "?phd-id=" +
                params.get("phd-id"));
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const json = await response.json();
            return json;
        }
        catch (e) {
            console.error(e);
        }
        return null;
    }
    initAndRender() {
        this.queryData().then((detailData) => {
            if (!detailData)
                throw new Error("API did not return enough data");
            // this.root.appendChild(StripeComponent.render());
            this.root.appendChild(IntroComponent.render(detailData));
            this.root.appendChild(MainContentComponent.render(detailData));
            this.root.appendChild(ContactComponent.render(detailData));
            this.root.appendChild(GetInTouchComponent.render(detailData));
        });
    }
}
class GetInTouchComponent {
    static render(data) {
        const application = data.content.find((x) => x.type === "contentList" && x.data.id === "application");
        const applicationLink = application?.blocks.find((x) => x.type === "textWithLink");
        const requiredDocumentsContentList = data.content.find((x) => x.type === "contentList" && x.data.id === "required-documents");
        const requiredDocumentsList = requiredDocumentsContentList?.blocks.find((x) => x.type === "unorderedList");
        const wrapper = document.createElement("div");
        wrapper.classList.add("phd-detail-get-in-touch-wrapper", "container-fluid");
        const container = document.createElement("div");
        container.classList.add("phd-detail-get-in-touch-container", "container");
        const content = document.createElement("div");
        content.classList.add("phd-detail-get-in-touch-content", "col-12", "col-md-9", "col-lg-6");
        const smallImageWrapper = document.createElement("div");
        smallImageWrapper.classList.add("phd-detail-get-in-touch-small-image-wrapper", "col-12", "col-md-3", "col-lg-6");
        smallImageWrapper.appendChild(Icon.phdIlluSmall());
        const largeImageWrapper = document.createElement("div");
        largeImageWrapper.classList.add("phd-detail-get-in-touch-large-image-wrapper");
        largeImageWrapper.appendChild(Icon.phdIlluLarge());
        const headline = document.createElement("p");
        headline.classList.add("phd-detail-get-in-touch-headline", "h3");
        headline.innerText = translate({
            de: "Du hast Interesse an diesem Job?",
            en: "You are interested in this job?",
        });
        const requiredDocuments = document.createElement("div");
        requiredDocuments.classList.add("phd-detail-required-documents");
        const requiredDocumentsHeadline = document.createElement("span");
        requiredDocumentsHeadline.classList.add("phd-detail-required-documents-headline");
        requiredDocumentsHeadline.innerText = translate({
            de: "Erforderliche Dokumente: ",
            en: "Required documents: ",
        });
        const requiredDocumentsContent = document.createElement("span");
        requiredDocumentsContent.classList.add("phd-detail-required-documents-content");
        requiredDocumentsContent.innerText =
            requiredDocumentsList?.data.items.join(", ") ?? "";
        const linkWrapper = document.createElement("div");
        linkWrapper.classList.add("phd-detail-get-in-touch-link-wrapper");
        const link = document.createElement("a");
        link.classList.add("phd-detail-get-in-touch-link");
        link.innerText = translate({
            de: "Auf diese Stelle bewerben",
            en: "Apply for this job",
        });
        link.href = applicationLink?.data.link.url ?? "";
        linkWrapper.appendChild(link);
        const waveWrapper = document.createElement("div");
        waveWrapper.classList.add("phd-detail-get-in-touch-wave-wrapper");
        waveWrapper.appendChild(Icon.phdWave());
        wrapper.appendChild(container);
        wrapper.appendChild(waveWrapper);
        wrapper.appendChild(largeImageWrapper);
        container.appendChild(content);
        container.appendChild(smallImageWrapper);
        content.appendChild(headline);
        content.appendChild(requiredDocuments);
        content.appendChild(linkWrapper);
        requiredDocuments.appendChild(requiredDocumentsHeadline);
        requiredDocuments.appendChild(requiredDocumentsContent);
        return wrapper;
    }
}
class DisclaimerComponent {
    static render(text) {
        const element = document.createElement("div");
        element.classList.add("phd-detail-disclaimer");
        element.innerText = text;
        return element;
    }
}
class IntroComponent {
    static render(data) {
        const headlineText = data.head.title;
        const sublineText = data.head.subline;
        const wrapper = document.createElement("div");
        wrapper.classList.add("phd-detail-intro-wrapper");
        const container = document.createElement("div");
        container.classList.add("phd-detail-intro-container", "container");
        wrapper.appendChild(container);
        const headline = document.createElement("h1");
        headline.classList.add("phd-detail-intro-headline");
        headline.innerText = headlineText;
        const subline = document.createElement("p");
        subline.classList.add("phd-detail-intro-subline");
        subline.innerText = sublineText;
        container.appendChild(headline);
        container.appendChild(subline);
        return wrapper;
    }
}
class TwoColumnsComponent {
    static render(hasHr = false) {
        const container = document.createElement("div");
        container.classList.add("phd-detail-main-container", "row", "container");
        const content = document.createElement("div");
        content.classList.add("phd-detail-main-content", "col-12", "col-md-9");
        const sidebar = document.createElement("div");
        sidebar.classList.add("phd-detail-main-sidebar", "col-12", "col-md-3");
        container.appendChild(content);
        if (hasHr) {
            container.appendChild(document.createElement("hr"));
        }
        container.appendChild(sidebar);
        return { container, content, sidebar };
    }
}
class MainContentComponent {
    static render(data) {
        const { container, content, sidebar } = TwoColumnsComponent.render(true);
        const headline = document.createElement("h2");
        headline.classList.add("phd-detail-main-headline");
        headline.innerText = translate({
            de: "Beschreibung",
            en: "Description",
        });
        container.prepend(headline);
        content.appendChild(DescriptionComponent.render(data));
        content.appendChild(BackToOverviewComponent.render(data));
        sidebar.appendChild(SidebarComponent.render(data));
        return container;
    }
}
class DescriptionComponent {
    static render(data) {
        const contentList = data.content.find((x) => x.type === "contentList" && x.data.id === "description");
        const textBlock = contentList?.blocks.find((x) => x.type === "text");
        const descriptionText = textBlock?.data.text ?? "";
        const container = document.createElement("div");
        container.classList.add("phd-detail-description-container");
        const description = document.createElement("div");
        container.classList.add("phd-detail-description-text", "richtext");
        description.innerHTML = descriptionText;
        container.appendChild(description);
        return container;
    }
}
class BackToOverviewComponent {
    static render(data) {
        const container = document.createElement("div");
        const link = document.createElement("a");
        link.addEventListener("click", () => history.back());
        link.href = "#";
        link.innerText = translate({
            de: "Zurück zur Übersicht",
            en: "Back to overview",
        });
        container.appendChild(link);
        return container;
    }
}
const townkeywords = ["town", "ort", "stadt", "city", "location", "standort"];
function townFromKeyfactsIfNeeded(data, blocks) {
    const exists = blocks.find((b) => townkeywords.some((key) => b.data.headline.toLowerCase().includes(key)));
    if (exists)
        return blocks;
    const locationFact = data?.keyFacts?.data?.items?.find((x) => x.icon === "pin-detail");
    if (!locationFact)
        return blocks;
    const town = {
        type: "text",
        data: {
            headline: locationFact.text,
            text: locationFact.name,
        },
    };
    return [town, ...blocks];
}
class SidebarComponent {
    static render(data) {
        const contentList = data.content.find((x) => x.type === "contentList" && x.data.id === "overview");
        const container = document.createElement("div");
        container.classList.add("phd-detail-sidebar-container");
        container.style.display = "flex";
        container.style.flexDirection = "column";
        const blocks = contentList?.blocks ?? [];
        for (const block of contentList?.blocks ?? []) {
            const item = document.createElement("div");
            item.classList.add("phd-detail-sidebar-item");
            item.style.display = "flex";
            const itemIcon = () => {
                switch (block.data.headline) {
                    case "Laufzeit":
                    case "Time Span":
                        return Icon.event();
                    case "Bewerbungsende":
                    case "Application Deadline":
                    case "Bewerbungsfrist endet am":
                        return Icon.eventDeadline();
                    case "Arbeitssprache":
                    case "Working Language":
                        return Icon.language();
                    case "Open Positions":
                    case "Anzahl offener Stellen":
                        return Icon.vacancies();
                    case "Finanzierung":
                    case "Financing":
                        return Icon.financing();
                    case "Art der Promotion":
                    case "Type of Position":
                        return Icon.promotion();
                    case "Erforderlicher Abschluss":
                    case "Required Degree":
                        return Icon.master();
                    case "Areas of study":
                    case "Studienfelder":
                        return Icon.fields();
                    default:
                        if (townkeywords.some((key) => block.data.headline.toLowerCase().includes(key))) {
                            return Icon.address();
                        }
                        return Icon.event();
                }
            };
            const itemContent = document.createElement("div");
            itemContent.classList.add("phd-detail-sidebar-item-content");
            itemContent.style.display = "flex";
            itemContent.style.flexDirection = "column";
            const itemHeadline = document.createElement("h4");
            itemHeadline.classList.add("phd-detail-sidebar-item-headline");
            itemHeadline.innerText = block.data.headline;
            const itemText = document.createElement("div");
            itemText.classList.add("phd-detail-sidebar-item-text");
            switch (block.type) {
                case "text":
                    itemText.innerHTML = block.data.text;
                    itemText.classList.add("richtext");
                    break;
                case "unorderedList":
                    itemText.innerText = block.data.items.join(", ");
                    break;
                case "textWithLink":
                    const link = document.createElement("a");
                    link.href = block.data.link.url;
                    link.innerText = block.data.link.text;
                    itemText.appendChild(link);
                    break;
            }
            itemContent.appendChild(itemHeadline);
            itemContent.appendChild(itemText);
            item.appendChild(itemIcon());
            item.appendChild(itemContent);
            container.appendChild(item);
        }
        return container;
    }
}
class ContactComponent {
    static render(data) {
        const { container, content, sidebar } = TwoColumnsComponent.render();
        content.appendChild(ContactInfoComponent.render(data));
        const disclaimer = DisclaimerComponent.render(translate({
            de: "Rechtlicher Hinweis: Die Informationen auf dieser Webseite werden dem DAAD von Dritten zur Verfügung gestellt. Trotz sorgfältiger Prüfung kann der DAAD für die Richtigkeit und Vollständigkeit keine Gewähr leisten.",
            en: "Legal notice: The information on this website is provided to the DAAD by third parties. Despite careful checking, the DAAD cannot guarantee the accuracy and completeness.",
        }));
        if (disclaimer)
            sidebar.appendChild(disclaimer);
        const wrapper = document.createElement("div");
        wrapper.classList.add("phd-detail-contact-wrapper");
        wrapper.appendChild(container);
        return wrapper;
    }
}
class ContactInfoComponent {
    static render(data) {
        const sidebarHead = data.sidebar.find((x) => x.type === "sidebarHead");
        const contactList = data.sidebar.find((x) => x.type === "contactList");
        const contact = contactList?.blocks.find((x) => x.type === "contact");
        const container = document.createElement("div");
        container.classList.add("phd-detail-contact-container", "row");
        const imageLink = document.createElement("a");
        imageLink.classList.add("phd-detail-contact-image-link", "col-md-3", "col-12");
        imageLink.href = sidebarHead?.data.link.url ?? "";
        const image = document.createElement("img");
        image.classList.add("phd-detail-contact-image");
        image.src = sidebarHead?.data.logo.src.small ?? "";
        image.alt = sidebarHead?.data.logo.alt ?? "no alt text available";
        const content = document.createElement("div");
        content.classList.add("phd-detail-contact-content", "col-md-9", "col-12");
        const headline = document.createElement("p");
        headline.classList.add("phd-detail-contact-headline", "richtext");
        // there is no good way of differentiating the name/department/person from this headline
        // the best way would be to style each nth-child of this element
        headline.innerHTML = contact?.data.headline ?? "";
        const details = document.createElement("div");
        details.classList.add("phd-detail-contact-details");
        const createIconWithText = (icon, text, url) => {
            if (!text)
                return;
            const iconWithText = document.createElement("div");
            iconWithText.classList.add("phd-detail-contact-icon-with-text");
            const iconElement = icon;
            const textContent = document.createElement("div");
            textContent.classList.add("phd-detail-contact-text");
            if (url) {
                const link = document.createElement("a");
                link.classList.add("phd-detail-contact-link");
                link.href = url;
                link.innerText = text;
                textContent.appendChild(link);
            }
            else {
                textContent.innerText = text;
            }
            iconWithText.appendChild(iconElement);
            iconWithText.appendChild(textContent);
            details.appendChild(iconWithText);
        };
        createIconWithText(Icon.detailAddress(), [contact?.data.address, contact?.data.zip, contact?.data.city]
            .filter((x) => !!x)
            .join(""));
        createIconWithText(Icon.fon(), contact?.data.phone ?? "");
        createIconWithText(Icon.mail(), contact?.data.email.text ?? "", contact?.data.email.url);
        createIconWithText(Icon.fax(), contact?.data.fax ?? "");
        createIconWithText(Icon.globalHealth(), contact?.data.link.text ?? "", contact?.data.link.url);
        imageLink.appendChild(image);
        container.appendChild(imageLink);
        content.appendChild(headline);
        content.appendChild(details);
        container.appendChild(content);
        return container;
    }
}

import { valueSerializerByType } from "./serializer";
export function getPrimaryImage(item) {
    return (item.nodes
        ?.find((node) => node.name === "images")
        ?.nodes?.find((node) => getProperty(node, "primaryimage") === true) ?? null);
}
export function getProperty(item, propertyName) {
    if (!item)
        return null;
    const property = item.properties.find((p) => p.name === propertyName);
    if (!property)
        return null;
    const serializer = valueSerializerByType[property.type];
    if (!serializer) {
        console.error("No serializer for type " + property.type);
        return null;
    }
    if (property.multiple)
        return property.values.map((v) => serializer(v));
    if (property.values.length !== 1)
        return null;
    return serializer(property.values[0]);
}

export function QueryStringBuilder() {
    const nameValues = [];
    const self = {
        nameValues,
        add: (name, value) => {
            self.nameValues.push({ name: name, value: value });
            return self;
        },
        build: () => {
            const segments = [];
            for (const nameValue of self.nameValues)
                segments.push(encodeURIComponent(nameValue.name) +
                    "=" +
                    encodeURIComponent(nameValue.value));
            return segments.join("&");
        },
    };
    return self;
}

import { Observable, computed } from "../../utils/observable";
import { PHD_OVERVIEW_BASE_URL } from "../contants";
import { CheckboxGroup } from "../overview/facets/checkbox-group";
import { TreeSelect } from "../overview/facets/tree-select";
import { SearchInput } from "../overview/search";
import { translate } from "../overview/utils";
export class PhdTeaser {
    root;
    params = Observable(new URLSearchParams());
    constructor(id) {
        this.root = (document.getElementById(id) ?? null);
        if (!this.root) {
            throw new Error(`Element with id ${id} not found`);
        }
    }
    getQueryString() {
        return [...new Set([...this.params.value.keys()])]
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(this.params.value.getAll(key).join(","))}`)
            .join("&");
    }
    async queryFacetsData(controller) {
        try {
            const response = await fetch(`${PHD_OVERVIEW_BASE_URL}${document.documentElement.lang === "de" ? "de" : "en"}`, {
                signal: controller?.signal,
                method: "GET",
            });
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const json = (await response.json());
            return json;
        }
        catch (e) {
            console.error(e);
        }
        return null;
    }
    useSearchParams = () => {
        const set = (key, val) => {
            const params = this.params.value;
            params.delete(key);
            if (val instanceof Set) {
                params.delete(key);
                for (const v of val) {
                    params.append(key, v.toString());
                }
                const element = document.querySelector(`.phd-facets-combobox-button.${key}`);
                element?.classList.toggle("active", params.getAll(key).length > 0);
            }
            else if (val) {
                params.set(key, val.toString());
            }
            this.params.value = params;
        };
        const p = {
            params: this.params,
            create: (key, isArray = false) => [
                computed([this.params], (params) => {
                    return isArray
                        ? new Set(params.getAll(key))
                        : params.get(key);
                }),
                (val) => {
                    set(key, val);
                },
            ],
        };
        return p;
    };
    initAndRender(linkToOverview) {
        this.queryFacetsData().then((data) => {
            if (!data)
                return;
            const [search, setSearch] = this.useSearchParams().create(data.filters.search.name);
            const searchInput = new SearchInput(search, setSearch).render(data);
            const treeSelectData = data.filters.facets.items.find((item) => item.name === "phd-subjectGroup");
            if (!treeSelectData || treeSelectData.type !== "treeSelect")
                return;
            const treeSelectState = this.useSearchParams().create(treeSelectData.name, true);
            const treeSelect = new TreeSelect(...treeSelectState).render(treeSelectData);
            treeSelect.style.flex = "1 0 0";
            treeSelect.classList.add("w-100");
            const checkboxGroupData = data.filters.facets.items.find((item) => item.name === "phd-workingLanguage");
            console.log(checkboxGroupData);
            if (!checkboxGroupData ||
                checkboxGroupData.type !== "checkboxGroup")
                return;
            const checkboxGroupState = this.useSearchParams().create(checkboxGroupData.name, true);
            const checkboxGroup = new CheckboxGroup(...checkboxGroupState).render(checkboxGroupData);
            checkboxGroup.style.flex = "1 0 0";
            checkboxGroup.classList.add("w-100");
            const container = document.createElement("div");
            container.classList.add("flex", "flex-column", "gap-2");
            const searchButton = document.createElement("a");
            searchButton.classList.add("btn", "btn-primary");
            searchButton.innerText = translate({ de: "Suchen", en: "Search" });
            this.params.subscribeAndRun(() => (searchButton.href = `${linkToOverview}?${this.getQueryString()}`));
            const facetRow = document.createElement("div");
            facetRow.classList.add("d-flex", "flex-md-row", "flex-column", "gap-4");
            container.appendChild(searchInput);
            facetRow.appendChild(treeSelect);
            facetRow.appendChild(checkboxGroup);
            facetRow.appendChild(searchButton);
            container.appendChild(facetRow);
            this.root.appendChild(container);
        });
    }
}

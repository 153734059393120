import { translate } from "./utils";
export class Sort {
    sort;
    onChange;
    constructor(sort, onChange) {
        this.sort = sort;
        this.onChange = onChange;
    }
    render(result) {
        const sortContainer = document.createElement("div");
        sortContainer.classList.add("phd-sort-container");
        const headline = document.createElement("h3");
        headline.classList.add("phd-sort-headline", "h4");
        const text = translate({
            de: "Promotionsangebote",
            en: "PhD programmes",
        });
        result.subscribeAndRun((val) => {
            headline.innerText = `${val?.results.count ?? 0} ${text}`;
        });
        const sortSelectContainer = document.createElement("div");
        sortSelectContainer.classList.add("phd-sort-select-container");
        const sortSelectLabel = document.createElement("label");
        sortSelectLabel.innerText = result.value?.filters.sort.label ?? "";
        const sortSelect = document.createElement("select");
        sortSelect.classList.add("phd-sort-select-component");
        const option = document.createElement("option");
        option.classList.add("phd-sort-select-option");
        option.value = "";
        option.innerText = result.value?.filters.sort.placeholder ?? "";
        const hrContainer = document.createElement("div");
        hrContainer.classList.add("py-3", "phd-mobile-only", "w-100");
        hrContainer.appendChild(document.createElement("hr"));
        sortContainer.appendChild(headline);
        sortContainer.appendChild(hrContainer);
        sortContainer.appendChild(sortSelectContainer);
        sortSelectContainer.appendChild(sortSelectLabel);
        sortSelectContainer.appendChild(sortSelect);
        sortSelect.appendChild(option);
        for (const entry of result.value?.filters.sort.data ?? []) {
            const option = document.createElement("option");
            option.classList.add("phd-sort-select-option");
            option.value = entry.value;
            option.innerText = entry.label;
            this.sort.subscribeAndRun((val) => {
                option.selected = val === entry.value;
            });
            sortSelect.appendChild(option);
        }
        sortSelect.addEventListener("change", () => {
            this.onChange(sortSelect.value || null);
        });
        return sortContainer;
    }
}

import { ElementBuilder } from "../element-creator";
import { Observable } from "../utils/observable";
import { getPrimaryImage, getProperty } from "./property-helper";
const calendarIcon = `<?xml version="1.0" encoding="utf-8"?><svg icon="true" id="icon-calendar" class="icon-calendar" fill=currentColor  version="1.1" id="calender" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width='100%' height='100%' viewBox="0 0 20.004 20" enable-background="new 0 0 20.004 20" xml:space="preserve"> <path id="Vereinigungsmenge_4" fill="currentColor" d="M2.64,18.998C1.193,19.008,0.014,17.846,0,16.4V3.673c0-0.552,0.447-1,1-1h3V2 c0-0.552,0.447-1,1-1s1,0.447,1,1v0.674h1.377V2c0-0.552,0.447-1,1-1c0.553,0,1,0.447,1,1v0.674h1.16V2c0-0.552,0.447-1,1-1 c0.553,0,1,0.447,1,1v0.674h1.377V2c0-0.552,0.447-1,1-1s1,0.447,1,1v0.674H19c0.553,0,1,0.447,1,1v12.729 c-0.014,1.447-1.193,2.608-2.64,2.598L2.64,18.998z M2,16.4c0.012,0.342,0.299,0.611,0.641,0.599h14.721 c0.342,0.012,0.629-0.258,0.641-0.599V9.356H2.004L2,16.4z M17.998,7.357V4.672h-2.084v0.605c0,0.552-0.447,1-1,1 c-0.553,0-1-0.447-1-1V4.672h-1.377v0.605c0,0.552-0.447,1-1,1c-0.553,0-1-0.447-1-1V4.672h-1.16v0.605c0,0.552-0.447,1-1,1 s-1-0.447-1-1V4.672H6.001v0.605c0,0.552-0.447,1-1,1s-1-0.447-1-1V4.672h-2v2.684H17.998z"/></svg>`;
const closeIcon = '<svg icon="true" id="icon-close" class="icon-close" fill=currentColor  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 16.002 16" enable-background="new 0 0 16.002 16" xml:space="preserve"><path id="Vereinigungsmenge_5" fill="currentColor" d="M13.274,15.518l-5.291-5.448l-5.254,5.448c-0.604,0.607-1.572,0.644-2.223,0.088 c-0.609-0.523-0.678-1.443-0.152-2.052c0.02-0.023,0.041-0.047,0.064-0.07l5.301-5.499L0.418,2.522 c-0.564-0.574-0.557-1.496,0.018-2.06c0.023-0.021,0.047-0.043,0.07-0.064c0.648-0.56,1.617-0.523,2.223,0.082l5.289,5.452 l5.256-5.446c0.6-0.611,1.572-0.648,2.219-0.088c0.609,0.523,0.68,1.441,0.156,2.05c-0.02,0.023-0.041,0.047-0.063,0.068 l-5.311,5.499l5.301,5.468c0.564,0.564,0.563,1.48-0.002,2.043c-0.025,0.025-0.053,0.051-0.08,0.074 c-0.297,0.26-0.678,0.402-1.072,0.4C13.991,16,13.577,15.826,13.274,15.518z"/></svg>';
const uniqueId = ((prefix) => {
    let x = 0;
    return () => prefix + ++x;
})("idw-search-");
const createNewsContainer = () => ElementBuilder("div", [
    "d-flex",
    "flex-column",
    "px-2",
    "pb-2",
    "text-primary-dark",
    "text-break",
    "position-relative",
    "shadow",
    "rounded",
    "bg-white",
    "text-image-card",
    "align-self-start",
]);
const createNewsIconContainer = () => {
    return ElementBuilder("div", ["mt-n1"], {
        style: "height:32px",
    }).appendChild(ElementBuilder("div", [
        "icon-size-small",
        "icon-padding-small",
        "icon-bg-primary",
    ]).setInnerHTML(calendarIcon));
};
const createNewsImageContainer = (item) => {
    const imgNode = getPrimaryImage(item);
    if (!imgNode)
        return null;
    const imgSrc = getProperty(imgNode, "screen");
    if (!imgSrc)
        return null;
    const imgTitle = getProperty(imgNode, "title");
    const copyright = getProperty(imgNode, "source");
    const { button, overlay } = createCopyrightElements(Array.isArray(copyright)
        ? copyright[0].toString()
        : copyright?.toString() ?? null);
    return ElementBuilder("div", ["image-container-16x9", "mb-1"])
        .appendChild(ElementBuilder("img", ["object-cover", "image"], {
        src: Array.isArray(imgSrc) ? imgSrc[0] : imgSrc,
        alt: Array.isArray(imgTitle) ? imgTitle[0] : imgTitle,
    }))
        .appendChild(button)
        .appendChild(overlay);
};
const createCopyrightElements = (copyright) => {
    const controlId = uniqueId();
    if (!copyright)
        return { button: null, overlay: null };
    const openObserver = Observable(false);
    const overlay = ElementBuilder("div", [
        "position-absolute",
        "top-0",
        "start-0",
        "bottom-0",
        "end-0",
        "flex",
        "align-items-center",
        "justify-content-center",
        "d-none",
        "copyright-overlay",
        "d-none",
    ], { id: controlId }).appendChild(ElementBuilder("p", ["p-4", "text-white", "fw-bold"]).setInnerHTML(copyright));
    const button = ElementBuilder("button", [
        "icon-size-small",
        "icon-padding-small",
        "position-absolute",
        "top-0",
        "end-0",
        "flex",
        "align-items-center",
        "justify-content-center",
        "outline-none",
        "border-0",
        "copyright-button",
    ], {
        "aria-haspopup": "true",
        "aria-expanded": "false",
        "aria-controls": controlId,
        style: "pointer-events: all; border-radius:0px !important",
    })
        .appendChild(ElementBuilder("span", []).setInnerHTML("©"))
        .addEventListener("click", () => {
        openObserver.value = !openObserver.value;
    })
        .onCleanUp(() => openObserver.flushListeners());
    openObserver.subscribe((open) => {
        button.element.setAttribute("aria-expanded", open.toString());
        button.element.setAttribute("aria-label", open ? "Schließen" : "Öffnen");
        button
            .removeChildren()
            .appendChild(ElementBuilder("span").setInnerHTML(open ? closeIcon : "©"));
        if (open)
            overlay.element.classList.remove("d-none");
        else
            overlay.element.classList.add("d-none");
    });
    return { button, overlay };
};
const createNewsTextContainer = (item) => {
    const link = item?.path ?? null;
    const externalLink = getProperty(item, "externalLink");
    const officalDate = getProperty(item, "officialdate");
    let text = getProperty(item, "abstract");
    text = Array.isArray(text) ? text[0] : text;
    text = text?.toString() ?? null;
    let title = getProperty(item, "title");
    title = Array.isArray(title) ? title[0] : title;
    title = title?.toString() ?? null;
    const Anchor = ElementBuilder("a", ["link", "w-md-auto", "w-100", "cursor-pointer"], {
        href: `${window.location.origin}/idw-news${link}`,
        target: externalLink && 'target="_blank"',
    }).appendChild(ElementBuilder("h3", ["mb-1", "fw-bold"]).setInnerHTML(title));
    const textElement = ElementBuilder("div", ["line-clamp-4"]).setInnerHTML(text);
    const dateElement = ElementBuilder("span", [
        "align-self-start",
    ]).setInnerHTML(`<small>${officalDate}</small>`);
    return ElementBuilder("div", [
        "d-flex",
        "flex-column",
        "gap-2",
        "subpad-news-teaser",
    ])
        .appendChild(Anchor)
        .appendChild(textElement)
        .appendChild(dateElement);
};
export function renderNews(item) {
    return createNewsContainer()
        .appendChild(createNewsIconContainer())
        .appendChild(createNewsImageContainer(item))
        .appendChild(createNewsTextContainer(item)).element;
}

export function ElementBuilder(tag, classes = [], attributes = {}) {
    const element = document.createElement(tag);
    element.classList.add(...classes);
    for (const [key, value] of Object.entries(attributes))
        !!value && element.setAttribute(key, value.toString());
    const self = {
        children: [],
        cleanUpListener: new Set(),
        element: element,
        appendChild(child) {
            if (!child)
                return this;
            self.element.appendChild(child.element);
            self.children.push(child);
            return self;
        },
        setInnerHTML(html) {
            self.element.innerHTML = html ?? "";
            return self;
        },
        addEventListener(event, callback) {
            self.element.addEventListener(event, callback);
            self.cleanUpListener.add(() => self.element.removeEventListener(event, callback));
            return self;
        },
        removeChildren() {
            self.children.forEach((child) => child.cleanUp());
            self.children = [];
            return self;
        },
        cleanUp() {
            self.cleanUpListener.forEach((callback) => callback());
            self.children.forEach((child) => child.cleanUp());
            self.element.remove();
        },
        onCleanUp(callback) {
            self.cleanUpListener.add(callback);
            return self;
        },
    };
    return self;
}

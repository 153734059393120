import { translate } from "./utils";
export class Search {
    search;
    onChange;
    constructor(search, onChange) {
        this.search = search;
        this.onChange = onChange;
    }
    render(result) {
        const searchContainer = document.createElement("h2");
        searchContainer.classList.add("phd-search-container");
        const searchLabel = document.createElement("label");
        searchLabel.classList.add("h3", "phd-search-label");
        searchLabel.htmlFor = "phd-search";
        searchLabel.innerText = result.filters.search.label;
        const searchInputContainer = document.createElement("div");
        searchInputContainer.classList.add("phd-search-input-container");
        searchContainer.appendChild(searchLabel);
        searchContainer.appendChild(searchInputContainer);
        searchInputContainer.appendChild(new SearchInput(this.search, (v) => (this.search.value = v)).render(result));
        searchInputContainer.appendChild(new SearchButton(this.search, this.onChange).render());
        return searchContainer;
    }
}
export class SearchInput {
    search;
    onChange;
    constructor(search, onChange) {
        this.search = search;
        this.onChange = onChange;
    }
    render(result) {
        const searchInput = document.createElement("input");
        searchInput.classList.add("form-control", "phd-search-input");
        searchInput.id = "phd-search";
        searchInput.type = "search";
        searchInput.placeholder = result.filters.search.label;
        this.search.subscribeAndRun((value) => {
            searchInput.value = value || "";
        });
        searchInput.addEventListener("change", () => {
            // we only propagate the change if the user clicks the submit button
            this.onChange(searchInput.value || null);
        });
        return searchInput;
    }
}
export class SearchButton {
    search;
    onChange;
    constructor(search, onChange) {
        this.search = search;
        this.onChange = onChange;
    }
    render() {
        const searchButton = document.createElement("button");
        searchButton.classList.add("btn", "btn-primary");
        searchButton.addEventListener("click", () => this.onChange(this.search.value));
        searchButton.innerText = translate({ de: "Suchen", en: "Search" });
        return searchButton;
    }
}

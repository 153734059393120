"use strict";
/**
 * Top bar
 */
const searchbarToggleView = document.querySelectorAll(".js-searchbar-toggle-view");
const searchbarToggleOnActive = document.querySelectorAll(".js-searchbar-toggle-on-active");
const searchbarLangToggleOnActive = document.querySelector(".js-searchbar-lang-toggle-on-active");
const searchbarToggleIconGlas = document.querySelector(".js-searchbar-toggle-icon-glas");
const searchbarToggleIconX = document.querySelector(".js-searchbar-toggle-icon-x");
const searchbarShow = document.querySelector(".js-searchbar-show");
function handleOpen(parent) {
    searchbarToggleOnActive.forEach((object) => {
        object.classList.remove("d-lg-flex");
    });
    searchbarLangToggleOnActive?.classList.remove("d-sm-flex");
    searchbarToggleIconGlas?.classList.remove("d-flex");
    searchbarToggleIconGlas?.classList.add("d-none");
    searchbarToggleIconX?.classList.remove("d-none");
    searchbarToggleIconX?.classList.add("d-flex");
    // remove overflow hidden
    // remove w-0
    // searchbarShow?.classList.remove("w-0");
    searchbarShow?.classList.add("flex-grow-1");
    searchbarShow?.querySelector("input")?.focus();
    // searchbarShow?.setAttribute(
    //     "style",
    //     "display: flex;justify-content: flex-end;"
    // );
    parent?.setAttribute("data-isopen", "true");
}
function handleClose(parent) {
    searchbarToggleOnActive?.forEach((object) => {
        object.classList.add("d-lg-flex");
    });
    searchbarLangToggleOnActive?.classList.add("d-sm-flex");
    searchbarToggleIconGlas?.classList.remove("d-none");
    searchbarToggleIconGlas?.classList.add("d-flex");
    searchbarToggleIconX?.classList.remove("d-flex");
    searchbarToggleIconX?.classList.add("d-none");
    // add overflow hidden
    // add w-0
    // searchbarShow?.classList.add("w-0");
    // searchbarShow?.classList.remove("w-100");
    searchbarShow?.classList.remove("flex-grow-1");
    // searchbarShow?.removeAttribute("style");
    parent?.setAttribute("data-isopen", "false");
}
searchbarToggleView.forEach((object) => {
    object.addEventListener("click", function () {
        //@ts-ignore
        const parentItem = this.closest(".nav-item");
        const isOpen = parentItem?.getAttribute("data-isopen") === "true" ?? false;
        return isOpen ? handleClose(parentItem) : handleOpen(parentItem);
    });
});

import { Icon } from "../icons/icon";
import { translate } from "./utils";
export class Pagination {
    state;
    onChange;
    constructor(state, onChange) {
        this.state = state;
        this.onChange = onChange;
    }
    derivePageIdx(offset, numPerPage) {
        return Math.floor(this.toNumber(offset) / numPerPage);
    }
    toNumber(value) {
        const parsed = Number(value);
        return isNaN(parsed) ? 0 : parsed;
    }
    render(result) {
        const numPerPage = () => result.value?.filters.pagination.numPerPage ?? 0;
        const totalResults = () => result.value?.results.count ?? 0;
        const totalPages = () => Math.ceil(totalResults() / numPerPage());
        const container = document.createElement("div");
        container.classList.add("phd-pagination-container");
        const backButton = document.createElement("button");
        backButton.classList.add("phd-pagination-control-back");
        backButton.ariaLabel = translate({
            de: "Vorherige Seite",
            en: "last page",
        });
        const forwardButton = document.createElement("button");
        forwardButton.classList.add("phd-pagination-control-forward");
        forwardButton.ariaLabel = translate({
            de: "Nächste Seite",
            en: "next page",
        });
        backButton.appendChild(Icon.arrow("body"));
        forwardButton.appendChild(Icon.arrow("body"));
        let cleanupFns = [];
        result.subscribeAndRun((val) => {
            cleanupFns.forEach((fn) => fn());
            cleanupFns = [];
            container.innerHTML = "";
            if (!val || totalResults() === 0)
                return;
            container.appendChild(backButton);
            const pageIdx = this.derivePageIdx(this.state.value, numPerPage());
            for (const pageIndex of this.#getPaginationRange(pageIdx, totalPages())) {
                const { number, cleanup } = this.renderPageNumber(pageIndex, pageIdx, numPerPage(), () => this.onChange((pageIndex * numPerPage()).toString()));
                cleanupFns.push(cleanup);
                container.appendChild(number);
            }
            container.appendChild(forwardButton);
        });
        this.state.subscribeAndRun((val) => {
            const pageIndex = this.derivePageIdx(val, numPerPage());
            const firstPage = pageIndex === 0;
            backButton.disabled = firstPage;
            const lastPage = pageIndex === totalPages() - 1;
            forwardButton.disabled = lastPage;
        });
        backButton.addEventListener("click", () => this.onChange((Math.max(this.derivePageIdx(this.state.value, numPerPage()) - 1, 0) * numPerPage()).toString()));
        forwardButton.addEventListener("click", () => this.onChange((Math.min(this.derivePageIdx(this.state.value, numPerPage()) + 1, totalPages() - 1) * numPerPage()).toString()));
        return container;
    }
    range(num) {
        return [...new Array(num).fill(0).keys()];
    }
    getWindow(list, start, range) {
        const end = Math.min(list.length, start + range);
        return list.slice(start, end);
    }
    // Range will be inclusive
    #getPaginationRange(index, total) {
        const maxItems = Math.min(total, 5);
        const start = Math.max(0, index - Math.floor(maxItems / 2));
        const wind = this.getWindow(this.range(total), start, maxItems);
        return wind;
    }
    renderPageNumber(pageIndex, activePageIndex, itemsPerPage, click) {
        const number = document.createElement("button");
        number.classList.add("phd-pagination-control", "phd-pagination-control-number");
        number.innerText = (pageIndex + 1).toString();
        number.setAttribute("data-active", (pageIndex === activePageIndex).toString());
        number.disabled = pageIndex === activePageIndex;
        const unsub = this.state.subscribe((val) => number.setAttribute("data-active", (this.derivePageIdx(val, itemsPerPage) === pageIndex).toString()));
        number.addEventListener("click", click);
        return {
            number,
            cleanup: () => {
                unsub();
                number.removeEventListener("click", click);
            },
        };
    }
}

"use strict";
//@ts-check
export const anchorNavigation = {
    toggleNav(event) {
        const navigationWrapper = document.body?.querySelector("[data-anchor-navigation-wrapper]");
        const navigationContainer = navigationWrapper?.querySelector("[data-anchor-navigation-container]");
        if (!navigationContainer)
            return;
        const isOpen = navigationContainer.classList.contains("anchor-navigation-open");
        // toggle visibility of nav
        navigationContainer.classList.toggle("anchor-navigation-open");
        if (isOpen)
            return;
        // if was not opend we stop the event propagation to prevent a click on an anchor button
        event.stopPropagation();
        event.preventDefault();
    },
    onScroll(dataSrcElements, navigation) {
        const anchorButtons = [...navigation.children];
        const activeIdx = dataSrcElements.findIndex((el, idx) => {
            const { y } = el.getBoundingClientRect();
            const scrollPosition = Math.max(-1, y);
            return scrollPosition > -1;
        });
        anchorButtons.forEach((child, idx) => idx !== activeIdx
            ? child.classList.remove("active-anchor")
            : child.classList.add("active-anchor"));
        if (activeIdx !== -1 || anchorButtons.length === 0)
            return;
        return anchorButtons[anchorButtons.length - 1].classList.add("active-anchor");
    },
    init() {
        const navigationWrapper = document.body?.querySelector("[data-anchor-navigation-wrapper]");
        const navigationContainer = navigationWrapper?.querySelector("[data-anchor-navigation-container]");
        const navigation = navigationWrapper?.querySelector("[data-navigation]");
        // while init set visibilty to 0
        navigationContainer?.setAttribute("style", "opacity:0");
        const dataSrcElements = [
            ...(navigationWrapper?.querySelectorAll("[data-anchor]") ?? []),
        ];
        navigation && this.initAnchorButtons(dataSrcElements, navigation);
        // after loading all elements reset visibilty to 1
        window.setTimeout(() => navigationContainer?.setAttribute("style", "opacity:1"), 150);
        document?.addEventListener("click", () => {
            if (navigationContainer?.classList.contains("anchor-navigation-open")) {
                navigationContainer.classList.remove("anchor-navigation-open");
            }
        });
        window.addEventListener("scroll", () => navigation && this.onScroll(dataSrcElements, navigation));
        navigation && this.onScroll(dataSrcElements, navigation);
    },
    createAnchorButton(el, href) {
        const anchorButton = document.createElement("a");
        const anchorButtonClasses = [
            "anchor-nav-item",
            "link--no-text-decoration",
            "justify-content-md-end",
            "justify-content-between",
        ];
        anchorButton.classList.add(...anchorButtonClasses);
        anchorButton.setAttribute("href", href);
        const anchorLabel = document.createElement("div");
        anchorLabel.innerText = el.getAttribute("data-anchor") ?? "";
        anchorLabel.classList.add("anchor-nav-item-label");
        const scrollIndicator = document.createElement("div");
        scrollIndicator.classList.add("anchor-nav-item-scroll-indicator");
        const anchorMobileNavigationArrowIcon = document.createElement("div");
        anchorMobileNavigationArrowIcon.classList.add("icon-arrow-right", "d-md-none", "anchor-navigation-mobile-icon");
        anchorButton.appendChild(anchorLabel);
        anchorButton.appendChild(scrollIndicator);
        anchorButton.appendChild(anchorMobileNavigationArrowIcon);
        return anchorButton;
    },
    initAnchorButtons(dataSrcElements, navigation) {
        if (!navigation)
            return;
        // if there is no anchor the brezel must not be visible
        if (dataSrcElements?.length === 0) {
            const navigationContainer = document?.querySelector("[data-anchor-navigation-container]");
            navigationContainer
                ?.querySelector(".anchor-brezel")
                ?.classList.add("d-md-none");
            navigation.classList?.add("d-md-none");
        }
        for (const element of dataSrcElements) {
            /**
             * check if an anchor exists in the target element
             * if ther is no anchor set the navigation will scroll to the target element
             * otherwise the link in the target element will be used
             */
            const anchor = element.querySelector("a");
            navigation.appendChild(this.createAnchorButton(element, anchor?.href ?? `#${element.id}`));
        }
    },
};

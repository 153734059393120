import { Observable, computed } from "../../utils/observable";
import { PHD_OVERVIEW_BASE_URL } from "../contants";
import { Facets } from "./facets/facets";
import { Pagination } from "./pagination";
import { Result } from "./result";
import { Search } from "./search";
import { Sort } from "./sort";
export class PhdOverview {
    detailBaseUrl;
    root;
    params;
    apiData = Observable(null);
    constructor(id, detailBaseUrl) {
        this.detailBaseUrl = detailBaseUrl;
        this.root = (document.getElementById(id) ?? null);
        if (!this.root) {
            throw new Error(`Element with id ${id} not found`);
        }
        this.params = Observable(this.fromWindowLocation());
    }
    getQueryString() {
        return [...new Set([...this.params.value.keys()])]
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(this.params.value.getAll(key).join(","))}`)
            .join("&");
    }
    fromWindowLocation() {
        const search = window.location.search.startsWith("?")
            ? window.location.search.slice(1)
            : window.location.search;
        return search.split("&").reduce((params, keyValue) => {
            if (!keyValue)
                return params;
            const [key, val] = keyValue.split("=");
            decodeURIComponent(val)
                .split(",")
                .forEach((v) => params.append(key, v));
            return params;
        }, new URLSearchParams());
    }
    async queryData(controller) {
        try {
            const response = await fetch(`${PHD_OVERVIEW_BASE_URL}${document.documentElement.lang === "de" ? "de" : "en"}?${this.getQueryString()}`, { signal: controller.signal });
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const json = (await response.json());
            this.apiData.value = json;
            return json;
        }
        catch (e) {
            console.error(e);
        }
        return null;
    }
    useSearchParams = () => {
        const set = (key, val) => {
            const params = this.params.value;
            params.delete(key);
            if (val instanceof Set) {
                params.delete(key);
                for (const v of val) {
                    params.append(key, v.toString());
                }
                const element = document.querySelector(`.phd-facets-combobox-button.${key}`);
                element?.classList.toggle("active", params.getAll(key).length > 0);
            }
            else if (val) {
                params.set(key, val.toString());
            }
            this.params.value = params;
        };
        const p = {
            params: this.params,
            create: (key, isArray = false) => [
                computed([this.params], (params) => {
                    return isArray
                        ? new Set(params.getAll(key))
                        : params.get(key);
                }),
                (val) => {
                    set(key, val);
                },
            ],
        };
        return p;
    };
    init(data) {
        if (!data)
            throw new Error("API did not return data");
        const searchParams = this.useSearchParams();
        const container = document.createElement("div");
        container.classList.add("container");
        this.root.appendChild(container);
        const row = document.createElement("div");
        row.classList.add("row", "justify-content-center");
        container.appendChild(row);
        const col12 = document.createElement("div");
        col12.classList.add("col-12");
        row.appendChild(col12);
        const [search, setSearch] = searchParams.create(data.filters.search.name);
        col12.appendChild(new Search(search, setSearch).render(data));
        col12.appendChild(document.createElement("hr"));
        col12.appendChild(new Facets(searchParams, this.apiData).render());
        const div = document.createElement("div");
        div.classList.add("py-3");
        div.appendChild(document.createElement("hr"));
        col12.appendChild(div);
        const [sort, setSort] = searchParams.create(data.filters.sort.name);
        col12.appendChild(new Sort(sort, setSort).render(this.apiData));
        const contentBox = document.createElement("div");
        contentBox.classList.add("phd-result-container", "col-md-8", "col-12");
        contentBox.appendChild(new Result(this.apiData).render(this.detailBaseUrl));
        const [offset, setOffset] = searchParams.create(data.filters.pagination.offsetName);
        contentBox.appendChild(new Pagination(offset, setOffset).render(this.apiData));
        row.appendChild(contentBox);
    }
    initAndRender() {
        let controller = new AbortController();
        let fetching = false;
        let wasInitalized = false;
        this.params.subscribeAndRun((params) => {
            if (fetching) {
                controller.abort();
                controller = new AbortController();
            }
            fetching = true;
            let queryString = this.getQueryString();
            queryString = queryString ? `?${queryString}` : queryString;
            window.history.pushState(null, "", queryString);
            window.history.pushState(null, "", `?${this.getQueryString()}`);
            this.queryData(controller).then(() => {
                if (!wasInitalized) {
                    this.init(this.apiData.value);
                    // hacky override to force reload
                    const [_, o] = this.useSearchParams().create("validate", false);
                    o(null);
                }
                wasInitalized = true;
                fetching = false;
            });
        });
    }
}

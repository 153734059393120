import "@lottiefiles/lottie-player";
//@ts-ignore
import Alpine from "alpinejs";
import "moment";
import "slick-carousel";
//@ts-ignore
import bootstrap from "../node_modules/bootstrap/dist/js/bootstrap";
//@ts-ignore
import $ from "jquery";
//@ts-ignore
window.$ = $;
import "./cookie-consent";
import "./helper";
import { PhdDetail } from "./phd-client/detail/phd-detail-render";
import { PhdOverview } from "./phd-client/overview/phd-overview-render";
import { PhdTeaser } from "./phd-client/teaser/phd-teaser-render";
import "./revoke-cookie";
import "./search-client/idw-search-client";
import "./topbar";
import { anchorNavigation } from "./anchor-navigation";
//@ts-ignore
window.Alpine = Alpine;
Alpine.data("anchorNavigation", () => anchorNavigation);
Alpine.start();
//@ts-ignore
window.bootstrap = bootstrap;
window.onresize = function () {
    document.body.style.height = window.innerHeight + "px";
};
//@ts-ignore
window.onresize();
//@ts-ignore
//@ts-ignore
window.PhdOverview = PhdOverview;
//@ts-expect-error
window.PhdDetail = PhdDetail;
//@ts-expect-error
window.PhdTeaser = PhdTeaser;

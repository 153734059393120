import { Icon } from "../icons/icon";
export class Result {
    state;
    constructor(state) {
        this.state = state;
    }
    render(detailBaseUrl) {
        const resultsContainer = document.createElement("div");
        resultsContainer.appendChild(document.createElement("hr"));
        resultsContainer.classList.add("phd-result-container-entries");
        this.state.subscribeAndRun((val) => {
            resultsContainer.innerHTML = "";
            for (const entry of val?.results.items ?? []) {
                resultsContainer.appendChild(this.#renderItem(entry, detailBaseUrl));
                resultsContainer.appendChild(document.createElement("hr"));
            }
        });
        return resultsContainer;
    }
    #renderItem(entry, detailBaseUrl) {
        const link = document.createElement("a");
        const params = new URLSearchParams(entry.link.url);
        const idParam = params.toString().match(new RegExp(/(phd-id=[\d]*)/)) || "404";
        link.href = detailBaseUrl + "?" + idParam[0];
        link.style.textDecoration = "none";
        link.style.color = "black";
        const container = document.createElement("div");
        const subline = document.createElement("p");
        subline.classList.add("subheading");
        subline.innerHTML = entry.subline;
        const headline = document.createElement("h4");
        headline.innerText = entry.headline;
        const itemContainer = document.createElement("p");
        itemContainer.classList.add("phd-result-container-entries-taglist");
        itemContainer.style.display = "flex";
        link.appendChild(container);
        container.appendChild(subline);
        container.appendChild(headline);
        container.appendChild(itemContainer);
        for (const item of entry.items)
            itemContainer.appendChild(this.#renderIcon(item));
        return link;
    }
    #renderIcon(item) {
        const itemElement = document.createElement("div");
        itemElement.style.display = "flex";
        const iconCreator = iconCreatorByKey[item.icon];
        if (!iconCreator)
            return itemElement;
        const icon = iconCreator();
        const text = document.createElement("div");
        text.innerText = item.text;
        itemElement.appendChild(icon);
        itemElement.appendChild(text);
        return itemElement;
    }
}
const iconCreatorByKey = {
    "study-type": () => Icon.master(),
    calendar: () => Icon.event(),
    deadline: () => Icon.eventDeadline(),
    "phd-germany": () => Icon.promotion(),
    "pin-detail": () => Icon.address(),
    "speech-bubbles": () => Icon.language(),
};
